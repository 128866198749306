@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.siteHeader {
  position: sticky;
  top: 0;
  z-index: 125;
  width: 100%;
  background-color: $background;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem;

  @media (max-width: $md) {
    padding: 1.5rem;
  }

  .logoWrapper {
    display: flex;
    flex: 0 0 auto;
    width: 170px;
    cursor: pointer;

    img {
      width: 100%;
    }

    @media (max-width: $lg) {
      width: 130px;
    }

    @media (max-width: $md) {
      width: 80px;
    }
  }

  .searchWrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: $lg) {
      left: 50%;
      transform: translateX(-35%);

      &:lang(ar) {
        left: auto;
        right: 18%;
      }
    }
  }

  .mobileMenuWrapper {
    .mobileMenu {
      width: 24px;
      height: 24px;
      display: flex;
      cursor: pointer;

      .hamburgerMenu {
        display: flex;
        justify-content: center;
        align-items: center;

        .hamburger {
          width: 24px;
          height: 2px;
          background-color: #fff;
          position: relative;
        }

        .hamburger::before,
        .hamburger::after {
          content: '';
          position: absolute;
          width: 24px;
          height: 2px;
          background-color: #fff;
        }

        .hamburger::before {
          top: -7px;
        }

        .hamburger::after {
          top: 7px;
        }
      }

      .sideMenu {
        position: fixed;
        top: 0;
        right: -100%;
        width: 100%;
        height: 100%;
        background-color: $background;
        transition: right 0.3s ease;

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          height: 100%;
          display: flex;
          flex-direction: column;

          .closeIcon {
            display: flex;
            justify-content: end;
            padding: 1.5rem;
          }

          .convertLanguage {
            display: flex;
            justify-content: center;
          }

          .sideMenuRulebook {
            margin: 16px 24px;
            padding: 16px;
            border-top: 1px solid $primary-500;
            overflow-y: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;
            &::-webkit-scrollbar {
              display: none;
            }

            h6 {
              color: $primary;
              font-size: 1rem;
              margin-bottom: 16px;
            }
          }
        }
      }

      .active {
        right: 0;
      }
    }
  }

  .languageBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;

    span {
      font-size: 16px;
      color: #e5e9e8;
      padding: 0 5px;
    }

    @media (max-width: $md) {
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
