@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.HomePageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #E5E9E8;
    text-align: center;
    padding-top: 80px;

    img {
        margin-bottom: 50px;
    }

    .desc_1 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;

        &:lang(ar) {
            font-weight: 500;
        }
    }

    .desc_2 {
        font-size: 20px;
        font-weight: 400;
        width: 50%;
    }

    @media (max-width:$md) {
        padding-top: 50px;

        img {
            width: 200px;
            height: 150px;
        }

        .desc_2 {
            width: 100%;
        }
    }

    @media (max-width:$sm) {
        img {
            width: 100px;
            height: 75px;
            margin-bottom: 20px;
        }
        .desc_1,
        .desc_2 {
            font-size: 16px;
        }
    }
}