@import './pagination.scss';
@import './select.scss';
@import './dropdown.scss';

.ant-layout {
  position: relative;
  min-height: 100vh;
  background-color: $background;
  padding-bottom: 230px;

  @media (max-width: $sm) {
    padding-bottom: 205px;
  }
}
.ant-input-wrapper {
  background-color: $background;

  input {
    border-radius: 8px;
    background-color: $background;
    border: 1px $primary-500 solid !important;
    box-shadow: none !important;
    padding: 10px 13.5px;
    color: $white;
    font-size: 1rem;

    @media (max-width: $md) {
      padding: 5px 13.5px;
      border-radius: 4px;
      font-size: 0.825rem;
    }

    &:lang(ar) {
      border-radius: 0;
    }
  }

  .ant-input:first-child:lang(ar) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    @media (max-width: $md) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.ant-input-group-addon {
  padding: 0;
  border-radius: 8px;
  background-color: $primary;
  border: none;

  @media (max-width: $md) {
    border-radius: 4px;
  }

  &:lang(ar) {
    border-radius: 0;
  }
}

.ant-input-group-addon:last-child:lang(ar) {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  @media (max-width: $md) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-left: 0;
}

.ant-divider-horizontal {
  margin: 16px 0;
  border-top: 1px solid $primary-500;
}
