@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.ant-pagination {
    .ant-pagination-item-active {
        &:hover {
            a {
                color: $white;
            }
        }

        background-color: $primary;

        a {
            color: $white !important;
        }
    }

    .ant-pagination-jump-next {
        a {
            width: 100%;

            .ant-pagination-item-container {
                .ant-pagination-item-ellipsis {
                    opacity: 1 !important;
                    color: $text;
                    font-size: 0.75rem;
                }

                .anticon-double-right {
                    visibility: hidden;
                }
            }
        }
    }

    .ant-pagination-jump-prev {
        a {
            width: 100%;

            .ant-pagination-item-container {
                .ant-pagination-item-ellipsis {
                    opacity: 1 !important;
                    color: $text;
                    font-size: 0.75rem;
                }

                .anticon-double-right {
                    visibility: hidden;
                }
            }
        }
    }

    .ant-pagination-prev {
        border: none !important;
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item {
        border: 1px solid $primary-500 !important;
        border-radius: 4px;
        width: 40px !important;
        height: 40px !important;
        margin: 0px 4px !important;
        padding: 8px 0px;
        @media (max-width: $md) {
            padding: 2px 0px;
            width: 24px !important;
            height: 24px !important;
        }

        a {
            font-size: 14px;
            color: $text;
            @media (max-width: $md) {
                font-size: 10px;
            }
        }

        &:hover {

            a {
                font-size: 14px;
                color: $text;
            }
        }
    }


    .ant-pagination-prev,
    .ant-pagination-next {
        padding: 5px 0px;

        a {
            @media (max-width: $md) {
                top: -8px;
                position: relative;
            }

            img {
                width: 5px;
            }
        }
    }

    .ant-pagination-options {
        display: none;
    }

    &:lang(ar) {

        .ant-pagination-prev img,
        .ant-pagination-next img {
            transform: rotate(180deg);
        }
    }

}