@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.itemLabel{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: $text;
    font-size: 16px;
    word-break: break-word;
    line-height: 20px;
    padding: 24px 0;
    &:hover{
      color: $primary;
    }
}