@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.BackButtonWrapper {
    button {
        background-color: $background;
        border: 1px solid $text-500;
        border-radius: 4px;
        padding: 16px 14px;
        color: $text-500;
        font-size: 0.875rem;
        column-gap: 12px;
        display: flex;
        align-items: center;
        font-weight: bold;
        cursor: pointer;

        img {
            width: 16px;
        }

        @media (max-width: $md) {
            padding: 8px 12px;
        }
    }
}