$primary: #8dca74;
$primary-500: #2c3d3b;
$primary-250: #1e2f2d;
$text: #acacac;
$text-500: #e5e9e8;
$secondary: #fcfcfc;
$background: #111a19;
$background-light: #16211f;
$white: #ffffff;
$black: #000000;
$light-gray: #eaeaea;
