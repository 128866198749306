@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.navigationWrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  background-color: $background-light;
  border-radius: 8px;
  margin: 0 30px 50px;

  .navigationBtn {
    button {
      font-size: 14px;
      font-weight: bold;
      color: $text-500;
      background-color: $background;
      border: 1px solid $text-500;
      cursor: pointer;
      border-radius: 4px;
      padding: 14px 0;
      min-width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;

      &:lang(ar) {
        font-weight: 500;

        img {
          transform: rotate(180deg);
        }
      }

      @media (max-width: $sm) {
        font-size: 12px;
        min-width: 100px;
      }
    }
  }

  .prevBtn {
    width: 100%;
    display: flex;
    justify-content: start;
  }

  .nextBtn {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  @media (max-width: $sm) {
    padding: 10px 20px;
    border-radius: 4px;
    margin: 0 20px;
  }
}
