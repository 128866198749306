@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.siteFooter {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  padding: 70px 90px 42px;
  background: $background;
  border-top: 1px solid $primary-500;
  z-index: 101;

  @media (max-width: $sm) {
    padding: 24px 0px;
  }
  
  .logoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 40px;
      
      @media (max-width: $sm) {
        width: 28px;
      }
    }
  }

  .buttonText {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    color: $text;
    font-size: 1rem;
    a{
      color: $text;
    }
    @media (max-width: $md) {
      margin-top: 24px;
      font-size: 0.75rem;
    }
    @media (max-width: $sm) {
      flex-direction: column-reverse;
      .copyRight {
        margin-top: 12px;
      }
    }

    .createdBy {
      span {
        font-weight: bold;
      }
    }
  }
}
