@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.fade-enter {
  opacity: 0;
  z-index: 1;
  overflow: hidden;
}

.fade-enter-active {
  opacity: 1;
  z-index: 1;
  transition: opacity 1s ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1s ease-in;
  display: none;
}

@media (max-width: $md) {
  .site-layout {
    padding: 20px;
    .site-layout-background {
      padding: 0;
    }
  }
}
