@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.searchResultWrapper {
    padding: 16px 30px 60px 30px;
    background-color: $background;
    
    @media (max-width: $md) {
        padding: 8px 0px 40px;
    }

    .searchResultQueryWrapper {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin-top: 24px;
        color: $white;
        border-bottom: 1px solid $primary-500;
        padding-bottom: 12px;

        p {
            font-size: 1.25rem;
            margin: 0px;
            
            span {
                font-weight: bold;
            }
            
            @media (max-width: $md) {
                font-size: 0.875rem;
            }
        }

        .searchResultQueryTotal {
            font-size: 1rem;
            color: $text;
            
            @media (max-width: $md) {
                font-size: 0.75rem;
            }
        }
    }

    .resultListWrapper {
        display: flex;
        row-gap: 8px;
        flex-direction: column;
        padding: 16px 0px;
        
        @media (max-width: $md) {
            row-gap: 4px;
        }

    }

    .paginationWrapper {
        margin-top: 32px;
        
        @media (max-width: $md) {
            margin-top: 16px;
        }
    }
}