@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.ruleBookContentItem {
            
    &:last-child {
        padding-bottom: 10px;
    }

    .ruleBookContentCatName {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        cursor: pointer;
        color: $white;
        margin-bottom: 8px;

        img {
            margin-top: 8px;
        }
    }

    .ruleBookContentItemChildren {
        height: 100%;
        padding: 8px 0px 8px 16px;
        border-left: 1px solid $primary-250;
        min-width: 195px;
        
        &:lang(ar){
            padding: 8px 16px 8px 0px;
            border-left: none;
            border-right: 1px solid $primary-250;
        }

        .ruleBookContentPageName {
            color: $white;
            margin-bottom: 8px;
            cursor: pointer;
            position: relative;

            img {
                position: absolute;
                left: -28px;
                top: 6px;

                &:lang(ar) {
                    transform: rotate(180deg);
                    right: -28px;
                    top: 6px;
                }
            }
        }

        .open {
            color: $primary;
        }
        
        .ruleBookContentPageNameOpen {
            color: red;
        }
    }
}