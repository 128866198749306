@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.SearchResultItemWrapper {
    color: $white;
    font-size: 1.25rem;
    padding: 16px 0px;
    
    @media (max-width: $md) {
        font-size: 0.875rem;
    }

    span {
        cursor: pointer;
        
        &:hover {
            text-decoration: underline;
        }
    }
}