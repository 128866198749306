@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.ant-dropdown {
  border: 1px solid $primary-250;
  border-radius: 5px;
  z-index: 110;
}

.ant-dropdown-menu {
  background-color: $background-light;
  box-shadow: none;
  max-height: 150px;
  overflow-y: auto;
  z-index: 110;
  padding: 0;
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $primary-500;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary-250;
    border-radius: 10px;
  }
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  color: $text;
  &:focus-visible {
    outline: none;
  }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: $background-light;
}
.ant-dropdown-menu-title-content {
  word-break: break-word;
  max-width: 250px;
  &:hover {
    color: $primary;
  }
}
