@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.PageWrapper {
  padding: 0 30px 20px;
  color: $light-gray;

  .titleSequence {
    color: $text;
    font-size: 14px;

    @media (max-width: $xs) {
      font-size: 10px;
    }
  }

  .PageTitleWrapper {
    display: flex;
    justify-content: space-between;

    .PageTitle {
      font-weight: bold;
      font-size: 24px;

      @media (max-width: $sm) {
        font-size: 16px;
      }
    }
  }

  .PrintBtn {
    button {
      font-size: 20px;
      font-weight: bold;
      color: #121514;
      background-color: $white;
      cursor: pointer;
      border: none;
      padding: 6px 22px;
      border-radius: 4px;

      &:lang(ar) {
        font-weight: 500;
      }

      @media (max-width: $md) {
        font-size: 16px;
        padding: 5px 20px;
      }

      @media (max-width: $sm) {
        font-size: 14px;
        padding: 2px 16px;
      }
    }
  }

  .content {
    div {
      word-break: break-word;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }

  @media (max-width: $md) {
    padding: 0;

    span {
      padding-bottom: 16px;
    }
  }
}
