@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

@font-face {
  font-family: 'HankenGrotesk';
  src: local('HankenGrotesk'), url(./fonts/HankenGrotesk-VariableFont_wght.ttf) format('truetype');
}
@font-face {
  font-family: '29LT_Kaff';
  src: local('29LT_Kaff'), url(./fonts/29LT-Kaff-Medium.ttf) format('truetype');
}
body {
  font-family: 'HankenGrotesk';
}

body:lang(ar) {
  font-family: '29LT_Kaff';
}
h1.ant-typography,
h2.ant-typography,
h3.ant-typography {
  color: $primary;
}

h1.ant-typography {
  font-size: 25px;
}

h2.ant-typography {
  font-size: 20px;
}

h3.ant-typography {
  font-size: 18px;
}
h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
label {
  color: $primary;
  font-weight: normal;
}
p.ant-typography {
  font-size: 18px;
}

span.ant-typography {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color:$white !important;
} 

@media (max-width: $md) {
  h1.ant-typography {
    font-size: 23px;
  }

  h2.ant-typography {
    font-size: 18px;
  }

  h3.ant-typography {
    font-size: 16px;
  }

  p.ant-typography {
    font-size: 16px;
  }
  a {
    font-size: 16px;
  }
}

@media (max-width: $sm) {
  a {
    font-size: 14px;
  }
  h1.ant-typography {
    font-size: 20px;
  }
  span.ant-typography {
    font-size: 14px;
  }
}
