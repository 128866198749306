@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.SearchBarWrapper {
    .SearchBarButton {
        cursor: pointer;
        padding: 11px 14px;
        
        @media (max-width: $md) {
            padding: 5px 9px;
        }
    }
}