@import 'theme/antd-custom-theme.css';
@import 'theme/typography.scss';
@import 'theme/global.scss';

@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

body {
  font-size: 23px;
  color: $primary;
}

@media (max-width: $md) {
  body {
    font-size: 18px;
  }
}
