@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.ruleBookWrapper {
    display: flex;

    .ruleBook {
        height: 100%;
        padding: 24px 24px 24px 40px;
        border-right: 1px solid $primary-500;
        overflow-x: hidden;
        overflow-y: scroll;
        position: fixed;

        &:lang(ar){
            padding: 24px 40px 24px 24px;
            border-right: none;
            border-left: 1px solid $primary-500;
        }
        
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
        }

        h6 {
            color: $primary;
            font-size: 1rem;
        }

        .ruleBookContent {
            position: absolute;
            margin-top: 16px;
            margin-right: 24px;
            width: 197px;
            
            &:lang(ar){
                margin-right: 0px;
                margin-left: 24px;
            }
        }

        .ruleBookContentBlock {
            height: 400px;
        }
    }

    .holdBlock {
        overflow: hidden;
    }

    .openDivider {
        position: absolute;
        background-color: $primary-500;
        left: 40px;
        height: 100%;
    
        &:lang(ar) {
            right: 40px;
            left: auto;
        }
    }

    .ruleBookChildren {
        width: 100%;
        min-height: 100vh;
        position: relative;

    }
}

.rulebookArrow {
    cursor: pointer;
    position: fixed;
    left: 274px;
    margin-top: 27px;
    z-index: 105;
    
    &:lang(ar) {
        right: 274px;
        left: auto;
        transform: rotate(180deg);
    }
}

.arrowOpen {
    left: 36px;
    
    &:lang(ar) {
        right: 36px;
        left: auto;
        transform: rotate(180deg);
    }
}