@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.paginationWrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: $md) {
        flex-direction: column;
        align-items: center;
        row-gap: 16px;
    }

    .paginationSelect {
        display: flex;
        column-gap: 8px;
        align-items: center;
        span {
            color: $text;
            font-size: 14px;
            
            @media (max-width: $md) {
                font-size: 12px;
            }
        }
    }
}