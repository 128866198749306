@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.ant-select-selector {
    background: $background !important;
    border: 1px solid $primary-500 !important;
    border-radius: 4px !important;
    color: $white !important;
    box-shadow: none !important;
            
    @media (max-width: $md) {
        height: 24px !important;
        padding: 0px 7px;
    }

    .ant-select-selection-item {
        color: $white !important;
        display: flex;
        align-items: center;
    }
}

.ant-select-dropdown {
    background: $background !important;
    border: 1px solid $primary-500 !important;
    border-radius: 4px !important;
    color: $white !important;
    box-shadow: none !important;
}

.ant-select-item {
    color: $white !important;
    background: $background !important;

    &:hover {
        background-color: $primary-500 !important;
    }
}

.ant-select-item-option-selected {
    background-color: $primary !important;

}