@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.menuItemsWrapper {
  background-color: $background-light;
  position: relative;
  z-index: 110;
  .menuItems {
    .menuItemsContent {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 30px;
      padding: 0 40px;
    }
  }
}
